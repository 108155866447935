
const Footerscreen = () => {
  
  const onSubmit = async (event) => {
    event.preventDefault();
    alert("Sorry, unable to subscribe now");
    // custom form handling here
  }

  
    return (
      <>
<div className="footer__area pt-100 pb-100" data-background="assets/img/footer.png">
<div className="container">
  <div className="row">
    <div className="col-xl-4 col-lg-5 col-md-8 md-mb-30">
      <div className="footer__area-footer-widget">
        <div className="footer__area-logo">
          <a href="/">
            <img src="assets/img/logo.png" alt="" />
          </a>
        </div>
        <div className="footer__area-content">
          <p>Tech Troup aims to bridge the gap between Companies and highly-skilled Talents. We are ready to serve with all your business needs.</p>
        </div>
      </div>
    </div>
    <div className="col-xl-2 col-lg-3 fo-b col-md-4">
      <div className="footer__area-footer-widget">
        <div className="footer__area-title">
          <h3>Company</h3>
        </div>
        <div className="footer__area-footer-menu">
          <ul>
            <li><a href="services-details">- Software Development</a>
            </li>
            <li><a href="services-details">- IT Consulting</a>
            </li>
            <li><a href="services-details">- Placement Consulting</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div className="col-xl-3 col-lg-4 col-md-6 md-mb-30">
      <div className="footer__area-footer-widget">
        <div className="footer__area-title">
          <h3>Information</h3>
        </div>
        <div className="footer__area-contact-info">
          <div className="footer__area-contact-info-item d-inline-flex mb-20">
            <div className="footer__area-contact-info-item-icon">
              <i className="fas fa-phone-alt" />
            </div>
            <div className="footer__area-contact-info-item-text">
              <a href="tel:+919533024675">+91-9533024675</a>
            </div>
          </div>
          <div className="footer__area-contact-info-item d-inline-flex mb-20">
            <div className="footer__area-contact-info-item-icon">
              <i className="fas fa-envelope" />
            </div>
            <div className="footer__area-contact-info-item-text">
              <a href="mailto:hello@techtroup.com">hello@techtroup.com</a>
            </div>
          </div>
          <div className="footer__area-contact-info-item d-inline-flex">
            <div className="footer__area-contact-info-item-icon">
              <i className="fas fa-map-marker-alt" />
            </div>
            <div className="footer__area-contact-info-item-text">
              <a href="#">#102, Mohan Shraddha Complex, Beside Bajaj Electronics, Habsiguda, Hyderabad</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="col-xl-3 col-lg-4 col-md-6">
      <div className="footer__area-footer-widget">
        <div className="footer__area-title">
          <h3>Get In Touch</h3>
        </div>
        <form onSubmit={onSubmit}>
          <input type="email" name="email" placeholder="Email" required="required" />
          <button type="submit" className="theme-banner-btn">submit Now</button>
        </form>
      </div>
    </div>
  </div>
</div>
</div>
{/* Footer Area End */}
{/* Copyright Area Start */}
<div className="footer__area-bottom">
<div className="container">
  <div className="row align-items-center pt-40 pb-40">
    <div className="col-xl-6 col-lg-6 col-md-7 md-mb-20">
      <div className="footer__area-bottom-left">
        <p>Copyright © 2022 - <a href="/">Tech Troup</a> All Right Reserved</p>
      </div>
    </div>
    <div className="col-xl-6 col-lg-6 col-md-5">
      <div className="footer__area-bottom-right t-right">
        {/* <ul className="d-inline-flex">
          <li><a href="#"><i className="fab fa-facebook-f" /></a>
          </li>
          <li><a href="#"><i className="fab fa-twitter" /></a>
          </li>
          <li><a href="#"><i className="fab fa-instagram" /></a>
          </li>
          <li><a href="#"><i className="fab fa-linkedin-in mr-0" /></a>
          </li>
        </ul> */}
      </div>
    </div>
  </div>
</div>
</div>

</>
  )
}

export default Footerscreen 
import Footerscreen from "./Footer"
import HeaderScreen from "./Header"


const ServicesScreen = () => {
  return (
    <>
    <section>
    <HeaderScreen/>
    <div>
        <div className="page__banner" data-background="assets/img/page-banner.png">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="page__banner-content">
                  <h1>Services</h1>
                  <div className="page__banner-menu">
                    <ul>
                      <li><a href="#"><i className="flaticon-home-icon-silhouette" /> Home - </a> 
                      </li>
                      <li> Services
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Page Banner Area End */}
        {/* Services Area Start */}
        <div className="services__area section-padding" data-background="assets/img/shape.png">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12">
                <div className="services__area-title section-title">
                  <p className="section-top">Our Services</p>
                  <h2>bridging the gap between Companies and highly-skilled Talents.</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-6 lg-mb-30">
                <div className="services__area-item">
                  <div className="services__area-item-icon">
                    <img src="assets/img/icon/services-1.png" alt="" />
                  </div>
                  <h3><a href="services-details">Software Development Services</a></h3>
                  <p>We build software to meet business needs with a focus on both technical specifications and business requirements.</p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 md-mb-30">
                <div className="services__area-item">
                  <div className="services__area-item-icon">
                    <img src="assets/img/icon/services-2.png" alt="" />
                  </div>
                  <h3><a href="services-details">IT Consulting</a></h3>
                  <p>We provide IT Consulting Solutions that are strategic, help solve problems, are innovative which help your business evolve</p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6">
                <div className="services__area-item">
                  <div className="services__area-item-icon">
                    <img src="assets/img/icon/services-3.png" alt="" />
                  </div>
                  <h3><a href="services-details">Placement Consulting</a></h3>
                  <p>Our seamless recruitment process helps us find great talents and provide specific results.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        <Footerscreen/>
    </section>
    </>
  )
}

export default ServicesScreen 
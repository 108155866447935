import Footerscreen from "./Footer"
import HeaderScreen from "./Header"

const FAQScreen = () => {
  return (
    <>
   <HeaderScreen/>
   <div>
   <div>
    <div className="page__banner" data-background="assets/img/page-banner.png">
        <div className="container">
            <div className="row">
                <div className="col-xl-12">
                    <div className="page__banner-content">
                        <h1>FAQS</h1>
                        <div className="page__banner-menu">
                            <ul>
                                <li>
                                    <a href="/"><i className="flaticon-home-icon-silhouette" /> Home - </a>
                                </li>
                                <li> FAQS
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* Page Banner Area End */} {/* Faq Area Start */}
    <div className="faq__area-about-faq section-padding">
        <div className="container">
            <div className="row">
                <div className="col-xl-12 col-lg-12">
                    <div className="faq__area-collapse-faq">
                        <div className="faq__area-collapse-item">
                            <div className="faq__area-collapse-item-card">
                                <div className="faq__area-collapse-item-card-header">
                                    <h3>01. How does information technology work?</h3>
                                    <i className="flaticon-plus" />
                                </div>
                                <div className="faq__area-collapse-item-card-header-content faq__area-content-p" style={{display: 'none'}}>
                                    <p>Pellentesque et eros massa. Nulla porttitor dui vitae turpis tristique, sed vestibulum sapien molestie. Nulla fermentum maximus mi eget interdum. Pellentesque eget pharetra ipsum. Vestibulum at tincidunt velit. Mauris
                                        quis elit sit amet felis mollis volutpat non vitae dolor. Nullam eros sapien, malesuada semper lacinia sit amet, venenatis ut lectus. In ut dui maximus nunc molestie consequat a dignissim ipsum. Duis ligula tortor,</p>
                                </div>
                            </div>
                        </div>
                        <div className="faq__area-collapse-item">
                            <div className="faq__area-collapse-item-card">
                                <div className="faq__area-collapse-item-card-header">
                                    <h3>02. How can I become IT manager?</h3>
                                    <i className="flaticon-minus" />
                                </div>
                                <div className="faq__area-collapse-item-card-header-content faq__area-content-p active">
                                    <p>Pellentesque et eros massa. Nulla porttitor dui vitae turpis tristique, sed vestibulum sapien molestie. Nulla fermentum maximus mi eget interdum. Pellentesque eget pharetra ipsum. Vestibulum at tincidunt velit. Mauris
                                        quis elit sit amet felis mollis volutpat non vitae dolor. Nullam eros sapien, malesuada semper lacinia sit amet, venenatis ut lectus. In ut dui maximus nunc molestie consequat a dignissim ipsum. Duis ligula tortor,</p>
                                </div>
                            </div>
                        </div>
                        <div className="faq__area-collapse-item">
                            <div className="faq__area-collapse-item-card">
                                <div className="faq__area-collapse-item-card-header">
                                    <h3>03. What are the latest trends in IT?</h3>
                                    <i className="flaticon-plus" />
                                </div>
                                <div className="faq__area-collapse-item-card-header-content faq__area-content-p" style={{display: 'none'}}>
                                    <p>Pellentesque et eros massa. Nulla porttitor dui vitae turpis tristique, sed vestibulum sapien molestie. Nulla fermentum maximus mi eget interdum. Pellentesque eget pharetra ipsum. Vestibulum at tincidunt velit. Mauris
                                        quis elit sit amet felis mollis volutpat non vitae dolor. Nullam eros sapien, malesuada semper lacinia sit amet, venenatis ut lectus. In ut dui maximus nunc molestie consequat a dignissim ipsum. Duis ligula tortor,</p>
                                </div>
                            </div>
                        </div>
                        <div className="faq__area-collapse-item">
                            <div className="faq__area-collapse-item-card">
                                <div className="faq__area-collapse-item-card-header">
                                    <h3>04. How long should a business plan be? </h3>
                                    <i className="flaticon-plus" />
                                </div>
                                <div className="faq__area-collapse-item-card-header-content faq__area-content-p" style={{display: 'none'}}>
                                    <p>Pellentesque et eros massa. Nulla porttitor dui vitae turpis tristique, sed vestibulum sapien molestie. Nulla fermentum maximus mi eget interdum. Pellentesque eget pharetra ipsum. Vestibulum at tincidunt velit. Mauris
                                        quis elit sit amet felis mollis volutpat non vitae dolor. Nullam eros sapien, malesuada semper lacinia sit amet, venenatis ut lectus. In ut dui maximus nunc molestie consequat a dignissim ipsum. Duis ligula tortor,</p>
                                </div>
                            </div>
                        </div>
                        <div className="faq__area-collapse-item mb-0">
                            <div className="faq__area-collapse-item-card">
                                <div className="faq__area-collapse-item-card-header">
                                    <h3>05. How work the support policy? </h3>
                                    <i className="flaticon-plus" />
                                </div>
                                <div className="faq__area-collapse-item-card-header-content faq__area-content-p" style={{display: 'none'}}>
                                    <p>Pellentesque et eros massa. Nulla porttitor dui vitae turpis tristique, sed vestibulum sapien molestie. Nulla fermentum maximus mi eget interdum. Pellentesque eget pharetra ipsum. Vestibulum at tincidunt velit. Mauris
                                        quis elit sit amet felis mollis volutpat non vitae dolor. Nullam eros sapien, malesuada semper lacinia sit amet, venenatis ut lectus. In ut dui maximus nunc molestie consequat a dignissim ipsum. Duis ligula tortor,</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
   </div>
   <Footerscreen/>
    </>
  )
}

export default FAQScreen 
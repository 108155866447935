import Footerscreen from "../Footer"
import HeaderScreen from "../Header"

  
const HomePageMiddle = () => {
 
    return(
        
        <section>
 <div>
        <HeaderScreen/>
        {/* Header Area End */}
        {/* Banner Area Start */}
        <div className="banner__area" data-background="assets/img/banner.jpg">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="banner__area-title">
                  <span>We Provide the best consultation services</span>
                  <h2>We solve your problems with technology</h2>
                  <a className="theme-btn" href="contact">Free Consulting</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Banner Area End */}
        {/* About Area Start */}
        <div className="about__area section-padding">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-6 col-lg-6 lg-mb-30">
                <div className="about__area-left">
                  <div className="about__area-left-content">
                    <h6><span className="counter">5</span>+</h6>
                    <p>Years Experience</p>
                  </div>
                  <div className="about__area-left-image">
                    <img src="assets/img/about.jpg" alt="" />
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6">
                <div className="about__area-right">
                  <div className="about__area-right-title">
                    <p className="section-top">About Our Company</p>
                    <h2>Making us a part of your business</h2>
                    <p>Employing the services of a manpower is an efficient method of hiring and screening recruits for organizations as a way of adding quality and skilled members to your team.</p>
                  </div>
                  <div className="about__area-right-list">
                    <div className="about__area-right-list-item">
                      <div className="about__area-right-list-item-icon">
                        <img src="assets/img/icon/about-1.png" alt="" />
                      </div>
                      <div className="about__area-right-list-item-text">
                        <h4>Business improvement</h4>
                      </div>
                    </div>
                    <div className="about__area-right-list-item center">
                      <div className="about__area-right-list-item-icon">
                        <img src="assets/img/icon/about-2.png" alt="" />
                      </div>
                      <div className="about__area-right-list-item-text">
                        <h4>Team Placement</h4>
                      </div>
                    </div>
                    <div className="about__area-right-list-item">
                      <div className="about__area-right-list-item-icon">
                        <img src="assets/img/icon/about-3.png" alt="" />
                      </div>
                      <div className="about__area-right-list-item-text">
                        <h4>User Support</h4>
                      </div>
                    </div>
                  </div>
                  <div className="about__area-right-contact">
                    <div className="about__area-right-contact-item">
                      <a className="theme-btn" href="services">Discover More</a>
                    </div>
                    <div className="about__area-right-contact-item">
                      <div className="about__area-right-contact-item-call">
                        <div className="about__area-right-contact-item-call-icon">
                          <i className="flaticon-phone-call" />
                        </div>
                        <div className="about__area-right-contact-item-call-content">
                          <span>Phone :</span>
                          <p><a href="tel:+919533024675">+91-9533024675</a></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* About Area End */}
        {/* Services Area Start */}
        <div className="services__area section-padding" data-background="assets/img/shape.png">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12">
                <div className="services__area-title section-title">
                  <p className="section-top">Our Services</p>
                  <h2>bridging the gap between Companies and highly-skilled Talents.</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-6 lg-mb-30">
                <div className="services__area-item">
                  <div className="services__area-item-icon">
                    <img src="assets/img/icon/services-1.png" alt="" />
                  </div>
                  <h3><a href="services-details">Software Development Services</a></h3>
                  <p>We build software to meet business needs with a focus on both technical specifications and business requirements.</p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 md-mb-30">
                <div className="services__area-item">
                  <div className="services__area-item-icon">
                    <img src="assets/img/icon/services-2.png" alt="" />
                  </div>
                  <h3><a href="services-details">IT Consulting</a></h3>
                  <p>We provide IT Consulting Solutions that are strategic, help solve problems, are innovative which help your business evolve</p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6">
                <div className="services__area-item">
                  <div className="services__area-item-icon">
                    <img src="assets/img/icon/services-3.png" alt="" />
                  </div>
                  <h3><a href="services-details">Placement Consulting</a></h3>
                  <p>Our seamless recruitment process helps us find great talents and provide specific results.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Services Area End */}
       
        {/* Counter Area Start */}
        {/* <div className="counter__area section-padding">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-sm-6 lg-mb-30">
                <div className="counter__area-item">
                  <ul className="d-inline-flex align-items-center t-left">
                    <li><i className="flaticon-project-plan" />
                    </li>
                    <li>
                      <h2><span className="counter">225</span>K</h2>
                      <p>Completed Project</p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 md-mb-30">
                <div className="counter__area-item">
                  <ul className="d-inline-flex align-items-center t-left">
                    <li><i className="flaticon-customer-satisfaction" />
                    </li>
                    <li>
                      <h2><span className="counter">109</span>+</h2>
                      <p>National Award</p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 sm-mb-30">
                <div className="counter__area-item">
                  <ul className="d-inline-flex align-items-center t-left">
                    <li><i className="flaticon-badge" />
                    </li>
                    <li>
                      <h2><span className="counter">220</span>+</h2>
                      <p>Team Members</p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="counter__area-item">
                  <ul className="d-inline-flex align-items-center t-left">
                    <li><i className="flaticon-team" />
                    </li>
                    <li>
                      <h2><span className="counter">250</span>k</h2>
                      <p>Happy Client</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* Counter Area End */}
        {/* Team Area Start */}
        {/* <div className="team__area section-padding">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12">
                <div className="services__area-title section-title">
                  <p className="section-top">Our Team</p>
                  <h2>Our Expert Team Member</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-3 col-lg-3 col-md-6 lg-mb-30">
                <div className="team__area-item">
                  <div className="team__area-image p-relative">
                    <div className="team__area-image-img">
                      <img src="assets/img/team-1.jpg" alt="" />
                    </div>
                    <div className="team__area-icon">
                      <ul>
                        <li><a href="#"><i className="fab fa-facebook-f" /></a>
                        </li>
                        <li><a href="#"><i className="fab fa-twitter" /></a>
                        </li>
                        <li><a href="#"><i className="fab fa-instagram" /></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="team__area-content">
                    <h3>Michael jackson</h3>
                    <p>CEO Founder</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6 md-mb-30">
                <div className="team__area-item">
                  <div className="team__area-image p-relative">
                    <div className="team__area-image-img">
                      <img src="assets/img/team-2.jpg" alt="" />
                    </div>
                    <div className="team__area-icon">
                      <ul>
                        <li><a href="#"><i className="fab fa-facebook-f" /></a>
                        </li>
                        <li><a href="#"><i className="fab fa-twitter" /></a>
                        </li>
                        <li><a href="#"><i className="fab fa-instagram" /></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="team__area-content">
                    <h3>Michelle obama</h3>
                    <p>Developer</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6 md-mb-30">
                <div className="team__area-item">
                  <div className="team__area-image p-relative">
                    <div className="team__area-image-img">
                      <img src="assets/img/team-3.jpg" alt="" />
                    </div>
                    <div className="team__area-icon">
                      <ul>
                        <li><a href="#"><i className="fab fa-facebook-f" /></a>
                        </li>
                        <li><a href="#"><i className="fab fa-twitter" /></a>
                        </li>
                        <li><a href="#"><i className="fab fa-instagram" /></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="team__area-content">
                    <h3>Machine Kelly</h3>
                    <p>Sr. Designer</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6">
                <div className="team__area-item">
                  <div className="team__area-image p-relative">
                    <div className="team__area-image-img">
                      <img src="assets/img/team-4.jpg" alt="" />
                    </div>
                    <div className="team__area-icon">
                      <ul>
                        <li><a href="#"><i className="fab fa-facebook-f" /></a>
                        </li>
                        <li><a href="#"><i className="fab fa-twitter" /></a>
                        </li>
                        <li><a href="#"><i className="fab fa-instagram" /></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="team__area-content">
                    <h3>Michelle yeoh</h3>
                    <p>Sr. Markert</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* Team Area End */}
        {/* Why Choose Ua  Area Start */}
        <div className="choose__ua section-padding" data-background="assets/img/choose.png">
          <div className="choose__ua-left" data-background="assets/img/choose-us.jpg" />
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-lg-6" />
              <div className="col-xl-6 col-lg-6">
                <div className="s-table">
                  <div className="s-tablec">
                    <div className="choose__ua-right">
                      <p className="section-top">Why Choose Us</p>
                      <h5 className="mb-35">We build software to meet business needs with a focus on both technical specifications and business requirements.we are having in depth experience in engineering, designing, recruitment across domains and industry types.</h5>
                      <div className="choose__ua-right-list">
                        <div className="row">
                          <div className="col-sm-6 mb-30">
                            <div className="choose__ua-right-list-icon">
                              <i className="flaticon-technical-support" />
                              <h4>IT Consulting<br />Management</h4>
                            </div>
                          </div>
                          <div className="col-sm-6 sm-mb-30">
                            <div className="choose__ua-right-list-icon">
                              <i className="flaticon-customer-service" />
                              <h4>Technology Support<br />Management</h4>
                            </div>
                          </div>
                          <div className="col-sm-6 sm-mb-30">
                            <div className="choose__ua-right-list-icon">
                              <i className="flaticon-chip" />
                              <h4>Placement<br /> Consulting</h4>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="choose__ua-right-list-icon choose__ua-right-list-icon-hover">
                              <i className="flaticon-networking" />
                              <h4>Business<br />Analysis</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Why Choose Ua Area End */}
        {/* Skill Area Start */}
        {/* <div className="skill__area section-padding pb-0" data-background="assets/img/skill.jpg">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-8 col-lg-8 col-md-9 lg-mb-40">
                <div className="skill__area-title">
                  <h2 className="mb-35">Skill Standards for Information Technology.</h2>
                  <a className="theme-banner-btn" href="services">Discover More</a>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-3">
                <div className="skill__area-play"> <a className="skill__area-video-icon video-popup" href="https://www.youtube.com/watch?v=0WC-tD-njcA"><span /></a>
                </div>
              </div>
            </div>
            <div className="skilbar__area-box p-relative">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div className="skilbar__area-item mb-30">
                    <div className="skilbar__area-content">
                      <h5>Software development</h5>
                      <span className="skilbar__area-count">
                        <span className="counter">75</span>%</span>
                    </div>
                    <div className="skilbar__area-inner">
                      <div className="skilbar__area-bar" data-width={75} />
                    </div>
                  </div>
                  <div className="skilbar__area-item md-mb-30">
                    <div className="skilbar__area-content">
                      <h5>Web Development</h5>
                      <span className="skilbar__area-count">
                        <span className="counter">85</span>%</span>
                    </div>
                    <div className="skilbar__area-inner">
                      <div className="skilbar__area-bar" data-width={85} />
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div className="skilbar__area-item mb-30">
                    <div className="skilbar__area-content">
                      <h5>Cyber Security</h5>
                      <span className="skilbar__area-count">
                        <span className="counter">55</span>%</span>
                    </div>
                    <div className="skilbar__area-inner">
                      <div className="skilbar__area-bar" data-width={55} />
                    </div>
                  </div>
                  <div className="skilbar__area-item">
                    <div className="skilbar__area-content">
                      <h5>Data Recovery</h5>
                      <span className="skilbar__area-count">
                        <span className="counter">90</span>%</span>
                    </div>
                    <div className="skilbar__area-inner">
                      <div className="skilbar__area-bar" data-width={90} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* Pricing Area End */}
        {/* Testimonial Area start */}
        {/* <div className="testimonial__area-mt-175">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="swiper testimonial__area-bg testimonial-slider p-relative">
                  <div className="swiper-wrapper testimonial__area-group">
                    <div className="swiper-slide testimonial__area-item">
                      <div className="row align-items-center">
                        <div className="col-sm-3 md-mb-30">
                          <div className="testimonial__area-image p-relative">
                            <img src="assets/img/testmonials-1.jpg" alt="" />
                          </div>
                        </div>
                        <div className="col-sm-9">
                          <div className="testimonial__area-content p-relative">
                            <p className="mb-15">Pellentesque metus massa, porttitor ac mauris sit amet, lobortis bibendum quam. Phasellus vitae viverra nisi. Vivamus faucibus bibendum lacus, eleifend porta nibh fringilla nec. Phasellus commodo aliquam arcu, vitae
                              consectetur massa eleifend eu.</p>
                            <h5>Michelle Yeoh</h5>
                            <p>Developer</p> <i className="flaticon-right-quotes-symbol" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide testimonial__area-item">
                      <div className="row align-items-center">
                        <div className="col-sm-3 md-mb-30">
                          <div className="testimonial__area-image p-relative">
                            <img src="assets/img/testmonials-2.jpg" alt="" />
                          </div>
                        </div>
                        <div className="col-sm-9">
                          <div className="testimonial__area-content p-relative">
                            <p className="mb-15">Pellentesque metus massa, porttitor ac mauris sit amet, lobortis bibendum quam. Phasellus vitae viverra nisi. Vivamus faucibus bibendum lacus, eleifend porta nibh fringilla nec. Phasellus commodo aliquam arcu, vitae
                              consectetur massa eleifend eu.</p>
                            <h5>Michelle Yeoh</h5>
                            <p>Developer</p> <i className="flaticon-right-quotes-symbol" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="pagination-area text-right">
                    <div className="dots" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* Testimonial Area End */}
        {/* Blog Area Start */}
        {/* <div className="blog__area section-padding">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12">
                <div className="blog__area-title section-title">
                  <p className="section-top">Blog &amp; News</p>
                  <h2>Our Blog &amp; News</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-4 col-lg-6 xl-mb-30">
                <div className="blog__area-item">
                  <div className="blog__area-image">
                    <img src="assets/img/blog-2.jpg" alt="" />
                    <div className="blog__area-date">
                      <h3>19</h3>
                      <p>Jan,22</p>
                    </div>
                  </div>
                  <div className="blog__area-content">
                    <ul>
                      <li><i className="flaticon-user" /> Admin</li>
                      <li><i className="flaticon-bubble-chat" /> 3 Comment</li>
                    </ul>
                    <h3><a >Because databases are Updated so frequently</a></h3>
                    <p>Pellentesque metus massa, porttitor ac mauris sit amet,</p> <a >Read More</a>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 lg-mb-30">
                <div className="blog__area-item">
                  <div className="blog__area-image">
                    <img src="assets/img/blog-3.jpg" alt="" />
                    <div className="blog__area-date">
                      <h3>10</h3>
                      <p>Jan,22</p>
                    </div>
                  </div>
                  <div className="blog__area-content">
                    <ul>
                      <li><i className="flaticon-user" /> Admin</li>
                      <li><i className="flaticon-bubble-chat" /> 4 Comment</li>
                    </ul>
                    <h3><a >The Business Information Technology</a></h3>
                    <p>Pellentesque metus massa, porttitor ac mauris sit amet,</p> <a >Read More</a>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6">
                <div className="blog__area-item">
                  <div className="blog__area-image">
                    <img src="assets/img/blog-1.jpg" alt="" />
                    <div className="blog__area-date">
                      <h3>07</h3>
                      <p>Jan,22</p>
                    </div>
                  </div>
                  <div className="blog__area-content">
                    <ul>
                      <li><i className="flaticon-user" /> Admin</li>
                      <li><i className="flaticon-bubble-chat" /> 1 Comment</li>
                    </ul>
                    <h3><a >Product Idea Solution for New Generation</a></h3>
                    <p>Pellentesque metus massa, porttitor ac mauris sit amet,</p> <a >Read More</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* Blog Area End */}
        {/* Footer Area Start */}
      <Footerscreen/>
      </div>
       </section>
    )
}

export default HomePageMiddle

import { Outlet, Link } from "react-router-dom";
const HeaderScreen = () => {
    return (
      <>
      <section>
      <div className="header__area">
          {/* <div className="header__area-topbar-area">
            <div className="header__area-topbar custom-container">
              <div className="header__area-topbar-left">
                <div className="header__area-topbar-left-info md-mb-10">
                  <ul>
                    <li><a href="#"><i className="flaticon-placeholder" />#102, Mohan Shraddha Complex,Habsiguda, Hyderabad</a>
                    </li>
                    <li><a href="mailto:hello@techtroup.com"><i className="flaticon-email" />hello@techtroup.com</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="header__area-topbar-right">
                <div className="header__area-topbar-right-language">
                  <select name="select">
                    <option value={1}>English</option>
                    <option value={2}>Hindi</option>
                    <option value={3}>Tamil</option>
                  </select>
                </div>
                <div className="header__area-topbar-right-social">
                  <ul>
                    <li><a href="#"><i className="fab fa-facebook-f" /></a>
                    </li>
                    <li><a href="#"><i className="fab fa-twitter" /></a>
                    </li>
                    <li><a href="#"><i className="fab fa-instagram" /></a>
                    </li>
                    <li><a href="#"><i className="fab fa-linkedin-in" /></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div> */}
          {/* TopBar Area Start */}
          {/* Menu Bar Area Start */}
          <div className="header__area-menubar-area">
            <div className="header__area-menubar custom-container">
              <div className="header__area-menubar-logo">
                <a href="/">
                  <img src="assets/img/logo.png" alt="" />
                </a>
                <div className="responsive-menu" />
              </div>
              <div className="header__area-menubar-main-menu">
                <ul id="mobilemenu">
                  <li ><Link to="/">Home</Link>
                  </li>
                  <li ><Link to="services">Services</Link>
                  </li>
                  {/* <li className="menu-item-has-children"><Link to="#">Services</Link>
                    <ul className="sub-menu">
                      <li><Link to="services">Services</Link>
                      </li>
                      <li><Link to="services-details">Services Details</Link>
                      </li>
                    </ul>
                  </li> */}
                  <li ><Link to="about">About Us</Link>
                  </li>
                  {/* <li className="menu-item-has-children"><Link to="#">Page</Link>
                    <ul className="sub-menu">
                      <li><Link to="about">About Us</Link>
                      </li>
                      <li><Link to="pricing">Pricing</Link>
                      </li>
                      <li><Link to="faq">FAQ</Link>
                      </li>
                      <li><Link to="project">Projects</Link>
                      </li>
                      <li><Link to="project-details">Projects Details</Link>
                      </li>
                      <li><Link to="team">Team</Link>
                      </li>
                    </ul>
                  </li> */}
                  {/* <li className="menu-item-has-children"><Link to="#">News</Link>
                    <ul className="sub-menu">
                      <li><Link to="news-grid">News Grid</Link>
                      </li>
                      <li><Link to="news-standard">News Standard</Link>
                      </li>
                      <li><Link to="news-single">News Single</Link>
                      </li>
                    </ul>
                  </li> */}
                  <li><Link to="contact">Contact</Link>
                  </li>
                </ul>
              </div>
              <div className="header__area-menubar-right">
                <div className="header__area-menubar-right-search">
                  <div className="search">
                    <span className="header__area-menubar-right-search-icon open"><i className="flaticon-search" /></span>
                  </div>
                  <div className="header__area-menubar-right-search-box">
                    <form>
                      <input type="search" placeholder="Search Here....." />
                      <button type="submit"><i className="flaticon-search" />
                      </button>
                    </form> <span className="header__area-menubar-right-search-box-icon"><i className="flaticon-close" /></span>
                  </div>
                </div>
             
                {/* <div className="header__area-menubar-right-btn">
                  <a className="theme-btn" to="contact">get a quote</a>
                </div> */}
              </div>
            </div>

          </div>
          {/* Menu Bar Area Start */}
        </div>
      </section>
      </>
    )
  }
  
  export default HeaderScreen 
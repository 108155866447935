import Footerscreen from "./Footer"
import HeaderScreen from "./Header"

const ProjectsScreen = () => {
  return (
    <>
   <HeaderScreen/>
    <div>
    <div>
        <div className="page__banner" data-background="assets/img/page-banner.png">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="page__banner-content">
                  <h1>Projects</h1>
                  <div className="page__banner-menu">
                    <ul>
                      <li><a href="/"><i className="flaticon-home-icon-silhouette" /> Home - </a>
                      </li>
                      <li> Projects
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Page Banner Area End */}
        {/* Projects Area Start */}
        <div className="project__area section-padding">
          <div className="container">
            <div className="row">
              <div className="col-xl-4 col-lg-6 col-md-6 mb-30">
                <div className="project__area-item">
                  <img src="assets/img/monitoring-1.jpg" alt="" />
                  <div className="project__area-overly-page">
                    <h3><a href="project-details">Cyber security</a></h3>
                    <p>IT Technology</p>
                    <div className="project__area-overly-page-icon">
                      <a href="project-details"> <i className="flaticon-arrow-right" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6 mb-30">
                <div className="project__area-item">
                  <img src="assets/img/monitoring-2.jpg" alt="" />
                  <div className="project__area-overly-page">
                    <h3><a href="project-details">Data Recovery</a></h3>
                    <p>Technology</p>
                    <div className="project__area-overly-page-icon">
                      <a href="project-details"> <i className="flaticon-arrow-right" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6 mb-30">
                <div className="project__area-item project__area-item-hover">
                  <img src="assets/img/monitoring-3.jpg" alt="" />
                  <div className="project__area-overly-page">
                    <h3><a href="project-details">Business Support</a></h3>
                    <p>IT Support</p>
                    <div className="project__area-overly-page-icon">
                      <a href="project-details"> <i className="flaticon-arrow-right" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6 lg-mb-30">
                <div className="project__area-item">
                  <img src="assets/img/project-4.jpg" alt="" />
                  <div className="project__area-overly-page">
                    <h3><a href="project-details">Network Cabling</a></h3>
                    <p>IT Cabling</p>
                    <div className="project__area-overly-page-icon">
                      <a href="project-details"> <i className="flaticon-arrow-right" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6 lg-mb-30">
                <div className="project__area-item">
                  <img src="assets/img/project-5.jpg" alt="" />
                  <div className="project__area-overly-page">
                    <h3><a href="project-details">Development</a></h3>
                    <p>IT Development</p>
                    <div className="project__area-overly-page-icon">
                      <a href="project-details"> <i className="flaticon-arrow-right" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6">
                <div className="project__area-item">
                  <img src="assets/img/project-6.jpg" alt="" />
                  <div className="project__area-overly-page">
                    <h3><a href="project-details">Cloud Solutions</a></h3>
                    <p>IT Solutions</p>
                    <div className="project__area-overly-page-icon">
                      <a href="project-details"> <i className="flaticon-arrow-right" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>			
            <div className="row">
              <div className="col-lg-12">
                <div className="blog__area-pagination text-center mt-50">
                  <ul>
                    <li><a href="#"><i className="fas fa-chevron-left" /></a>
                    </li>
                    <li><a className="active" href="#">01</a>
                    </li>
                    <li><a href="#">02</a>
                    </li>
                    <li><a href="#">03</a>
                    </li>
                    <li><a href="#"><i className="fas fa-chevron-right" /></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
   <Footerscreen/>
    </>
  )
}

export default ProjectsScreen 
import Footerscreen from "./Footer"
import HeaderScreen from "./Header"

const AboutusScreen = () => {
  return (
    <>
   <HeaderScreen/>
   <div>
   <div>
    <div className="page__banner" data-background="assets/img/page-banner.png">
        <div className="container">
            <div className="row">
                <div className="col-xl-12">
                    <div className="page__banner-content">
                        <h1>About Us</h1>
                        <div className="page__banner-menu">
                            <ul>
                                <li>
                                    <a href="/"><i className="flaticon-home-icon-silhouette" /> Home - </a>
                                </li>
                                <li> About
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* Page Banner Area End */} {/* About Area Start */}
    <div className="about__area section-padding">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-xl-6 col-lg-6 lg-mb-30">
                    <div className="about__area-left">
                        <div className="about__area-left-content">
                            <h6><span className="counter">5</span>+</h6>
                            <p>Years Experience</p>
                        </div>
                        <div className="about__area-left-image">
                            <img src="assets/img/about.jpg" alt="" />
                        </div>
                    </div>
                </div>
                <div className="col-xl-6 col-lg-6">
                    <div className="about__area-right">
                        <div className="about__area-right-title">
                            <p className="section-top">About Company</p>
                            <h2>Making us a part of your business</h2>
                            <p>Employing the services of a manpower is an efficient method of hiring and screening recruits for organizations as a way of adding quality and skilled members to your team.</p>
                        </div>
                        <div className="about__area-right-list">
                            <div className="about__area-right-list-item">
                                <div className="about__area-right-list-item-icon">
                                    <img src="assets/img/icon/about-1.png" alt="" />
                                </div>
                                <div className="about__area-right-list-item-text">
                                    <h4>Business improvement</h4>
                                </div>
                            </div>
                            <div className="about__area-right-list-item center">
                                <div className="about__area-right-list-item-icon">
                                    <img src="assets/img/icon/about-2.png" alt="" />
                                </div>
                                <div className="about__area-right-list-item-text">
                                    <h4>Team Placement</h4>
                                </div>
                            </div>
                            <div className="about__area-right-list-item">
                                <div className="about__area-right-list-item-icon">
                                    <img src="assets/img/icon/about-3.png" alt="" />
                                </div>
                                <div className="about__area-right-list-item-text">
                                    <h4>User Support</h4>
                                </div>
                            </div>
                        </div>
                        <div className="about__area-right-contact">
                            <div className="about__area-right-contact-item">
                                <a className="theme-btn" href="services">Discover More</a>
                            </div>
                            <div className="about__area-right-contact-item">
                                <div className="about__area-right-contact-item-call">
                                    <div className="about__area-right-contact-item-call-icon">
                                        <i className="flaticon-phone-call" />
                                    </div>
                                    <div className="about__area-right-contact-item-call-content">
                                        <span>Phone :</span>
                                        <p><a href="tel:+919533024675">+91-9533024675</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* About Area End */} {/* Counter Area Start */}
    {/* <div className="counter__area section-padding pt-0">
        <div className="container">
            <div className="row">
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 lg-mb-30">
                    <div className="counter__area-item">
                        <ul className="d-inline-flex align-items-center t-left">
                            <li><i className="flaticon-project-plan" />
                            </li>
                            <li>
                                <h2><span className="counter">225</span>K</h2>
                                <p>Completed Project</p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 md-mb-30">
                    <div className="counter__area-item">
                        <ul className="d-inline-flex align-items-center t-left">
                            <li><i className="flaticon-customer-satisfaction" />
                            </li>
                            <li>
                                <h2><span className="counter">109</span>+</h2>
                                <p>National Award</p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 sm-mb-30">
                    <div className="counter__area-item">
                        <ul className="d-inline-flex align-items-center t-left">
                            <li><i className="flaticon-badge" />
                            </li>
                            <li>
                                <h2><span className="counter">220</span>+</h2>
                                <p>Team Members</p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                    <div className="counter__area-item">
                        <ul className="d-inline-flex align-items-center t-left">
                            <li><i className="flaticon-team" />
                            </li>
                            <li>
                                <h2><span className="counter">250</span>k</h2>
                                <p>Happy Client</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div> */}
    {/* Counter Area End */} {/* Skill Area Start */}
    <div className="skill__area section-padding pb-0" data-background="assets/img/skill.jpg">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-xl-8 col-lg-8 col-md-9">
                    <div className="skill__area-title">
                        <h2 className="mb-40">Skill Standards for Information Technology.</h2>
                    </div>
                </div>
            </div>
            <div className="skilbar__area-box p-relative">
                <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6">
                        <div className="skilbar__area-item mb-30">
                            <div className="skilbar__area-content">
                                <h5>Software Development</h5>
                                <span className="skilbar__area-count">
                        <span className="counter">75</span>%</span>
                            </div>
                            <div className="skilbar__area-inner">
                                <div className="skilbar__area-bar" data-width={75} />
                            </div>
                        </div>
                        <div className="skilbar__area-item md-mb-30">
                            <div className="skilbar__area-content">
                                <h5>Web Development</h5>
                                <span className="skilbar__area-count">
                        <span className="counter">85</span>%</span>
                            </div>
                            <div className="skilbar__area-inner">
                                <div className="skilbar__area-bar" data-width={85} />
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6">
                        <div className="skilbar__area-item mb-30">
                            <div className="skilbar__area-content">
                                <h5>IT Consulting</h5>
                                <span className="skilbar__area-count">
                        <span className="counter">75</span>%</span>
                            </div>
                            <div className="skilbar__area-inner">
                                <div className="skilbar__area-bar" data-width={75} />
                            </div>
                        </div>
                        <div className="skilbar__area-item">
                            <div className="skilbar__area-content">
                                <h5>Training</h5>
                                <span className="skilbar__area-count">
                        <span className="counter">70</span>%</span>
                            </div>
                            <div className="skilbar__area-inner">
                                <div className="skilbar__area-bar" data-width={70} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* Skill Area End */} {/* Team Area Start */}
    <div className="team__area section-padding">
        <div className="container">
            {/* <div className="row">
                <div className="col-xl-12 col-lg-12">
                    <div className="services__area-title section-title">
                        <p className="section-top">Our Team</p>
                        <h2>Our Expert Team Member</h2>
                    </div>
                </div>
            </div> */}
            {/* <div className="row">
                <div className="col-xl-3 col-lg-3 col-md-6 lg-mb-30">
                    <div className="team__area-item">
                        <div className="team__area-image p-relative">
                            <div className="team__area-image-img">
                                <img src="assets/img/team-1.jpg" alt="" />
                            </div>
                            <div className="team__area-icon">
                                <ul>
                                    <li>
                                        <a href="#"><i className="fab fa-facebook-f" /></a>
                                    </li>
                                    <li>
                                        <a href="#"><i className="fab fa-twitter" /></a>
                                    </li>
                                    <li>
                                        <a href="#"><i className="fab fa-instagram" /></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="team__area-content">
                            <h3>Michael jackson</h3>
                            <p>CEO Founder</p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 md-mb-30">
                    <div className="team__area-item">
                        <div className="team__area-image p-relative">
                            <div className="team__area-image-img">
                                <img src="assets/img/team-2.jpg" alt="" />
                            </div>
                            <div className="team__area-icon">
                                <ul>
                                    <li>
                                        <a href="#"><i className="fab fa-facebook-f" /></a>
                                    </li>
                                    <li>
                                        <a href="#"><i className="fab fa-twitter" /></a>
                                    </li>
                                    <li>
                                        <a href="#"><i className="fab fa-instagram" /></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="team__area-content">
                            <h3>Michelle obama</h3>
                            <p>Developer</p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 md-mb-30">
                    <div className="team__area-item">
                        <div className="team__area-image p-relative">
                            <div className="team__area-image-img">
                                <img src="assets/img/team-3.jpg" alt="" />
                            </div>
                            <div className="team__area-icon">
                                <ul>
                                    <li>
                                        <a href="#"><i className="fab fa-facebook-f" /></a>
                                    </li>
                                    <li>
                                        <a href="#"><i className="fab fa-twitter" /></a>
                                    </li>
                                    <li>
                                        <a href="#"><i className="fab fa-instagram" /></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="team__area-content">
                            <h3>Machine Kelly</h3>
                            <p>Sr. Designer</p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6">
                    <div className="team__area-item">
                        <div className="team__area-image p-relative">
                            <div className="team__area-image-img">
                                <img src="assets/img/team-4.jpg" alt="" />
                            </div>
                            <div className="team__area-icon">
                                <ul>
                                    <li>
                                        <a href="#"><i className="fab fa-facebook-f" /></a>
                                    </li>
                                    <li>
                                        <a href="#"><i className="fab fa-twitter" /></a>
                                    </li>
                                    <li>
                                        <a href="#"><i className="fab fa-instagram" /></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="team__area-content">
                            <h3>Michelle yeoh</h3>
                            <p>Sr. Markert</p>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    </div>
    {/* Team Area End */} {/* Development Area Start */}
    <div className="development__area section-padding" data-background="assets/img/skill.jpg">
        <div className="container">
            <div className="row">
                <div className="col-xl-12">
                    <div className="development__area-title t-center">
                        <h2 className="mb-30">Professional Software Development Company with IT Recruitment and Training</h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* Development Area End */} {/* Faq Area Start */}
    {/* <div className="faq__area-about section-padding">
        <div className="container">
            <div className="row">
                <div className="col-xl-6 col-lg-6">
                    <div className="faq__area-left p-relative">
                        <h2 className="mb-50">Techniques for the fast Of information</h2>
                        <div className="faq__area-image">
                            <img src="assets/img/faq.jpg" alt="" />
                        </div>
                    </div>
                </div>
                <div className="col-xl-6 col-lg-6">
                    <div className="faq__area-collapse">
                        <p className="section-top mb-20">FAQ</p>
                        <div className="faq__area-collapse-item">
                            <div className="faq__area-collapse-item-card">
                                <div className="faq__area-collapse-item-card-header">
                                    <h3>01. How does information technology work?</h3>
                                    <i className="flaticon-plus" />
                                </div>
                                <div className="faq__area-collapse-item-card-header-content" style={{display: 'none'}}>
                                    <p>Pellentesque ac accumsan massa, in accumsan enim. Vestibulum dui diam, aliquet eu quam vel, tempus egestas lorem. Maecenas quis ipsum libero.</p>
                                </div>
                            </div>
                        </div>
                        <div className="faq__area-collapse-item">
                            <div className="faq__area-collapse-item-card">
                                <div className="faq__area-collapse-item-card-header">
                                    <h3>02. How can I become IT manager?</h3>
                                    <i className="flaticon-minus" />
                                </div>
                                <div className="faq__area-collapse-item-card-header-content active">
                                    <p>Pellentesque ac accumsan massa, in accumsan enim. Vestibulum dui diam, aliquet eu quam vel, tempus egestas lorem. Maecenas quis ipsum libero.</p>
                                </div>
                            </div>
                        </div>
                        <div className="faq__area-collapse-item mb-0">
                            <div className="faq__area-collapse-item-card">
                                <div className="faq__area-collapse-item-card-header">
                                    <h3>03. What are the latest trends in IT?</h3>
                                    <i className="flaticon-plus" />
                                </div>
                                <div className="faq__area-collapse-item-card-header-content" style={{display: 'none'}}>
                                    <p>Pellentesque ac accumsan massa, in accumsan enim. Vestibulum dui diam, aliquet eu quam vel, tempus egestas lorem. Maecenas quis ipsum libero.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> */}
    {/* Faq Area End */} {/* Testimonial Area start */}
    {/* <div className="testimonial__area section-padding">
        <div className="container">
            <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="swiper testimonial__area-bg testimonial-slider p-relative">
                        <div className="swiper-wrapper testimonial__area-group">
                            <div className="swiper-slide testimonial__area-item">
                                <div className="row align-items-center">
                                    <div className="col-sm-3 md-mb-30">
                                        <div className="testimonial__area-image p-relative">
                                            <img src="assets/img/testmonials-1.jpg" alt="" />
                                        </div>
                                    </div>
                                    <div className="col-sm-9">
                                        <div className="testimonial__area-content p-relative">
                                            <p className="mb-15">Pellentesque metus massa, porttitor ac mauris sit amet, lobortis bibendum quam. Phasellus vitae viverra nisi. Vivamus faucibus bibendum lacus, eleifend porta nibh fringilla nec. Phasellus commodo aliquam arcu,
                                                vitae consectetur massa eleifend eu.</p>
                                            <h5>Michelle Yeoh</h5>
                                            <p>Developer</p> <i className="flaticon-right-quotes-symbol" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide testimonial__area-item">
                                <div className="row align-items-center">
                                    <div className="col-sm-3 md-mb-30">
                                        <div className="testimonial__area-image p-relative">
                                            <img src="assets/img/testmonials-2.jpg" alt="" />
                                        </div>
                                    </div>
                                    <div className="col-sm-9">
                                        <div className="testimonial__area-content p-relative">
                                            <p className="mb-15">Pellentesque metus massa, porttitor ac mauris sit amet, lobortis bibendum quam. Phasellus vitae viverra nisi. Vivamus faucibus bibendum lacus, eleifend porta nibh fringilla nec. Phasellus commodo aliquam arcu,
                                                vitae consectetur massa eleifend eu.</p>
                                            <h5>Michelle Yeoh</h5>
                                            <p>Developer</p> <i className="flaticon-right-quotes-symbol" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="pagination-area text-right">
                            <div className="dots" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> */}
</div>
   </div>
   <Footerscreen/>
    </>
  )
}

export default AboutusScreen 
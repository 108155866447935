import {useEffect} from 'react'
import { Route, Switch, BrowserRouter } from "react-router-dom";
import HomeScreen from "./components/HomeScreen";
import ContactScreen from './components/Contact';
import ServicesScreen from './components/Services';
import ServicesDetailsScreen from './components/Services-details';
import AboutusScreen from './components/Aboutus';
import FAQScreen from './components/FAQ';
import ProjectsScreen from './components/Projects';

const App = () => {
  function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
  useEffect(() => {
    if (window.location.href.includes('?ref=')) {
      let getAddress = getParameterByName('ref');
      let final = getAddress;
      localStorage.setItem('baseRef', final);
  }
  }, [])

  return (
    <>
      <BrowserRouter>
        <Switch>
          <Route path="/" exact component={HomeScreen} />
          <Route path="/contact" exact component={ContactScreen} />
          <Route path="/services" exact component={ServicesScreen} />
          <Route path="/services-details" exact component={ServicesDetailsScreen} />
          <Route path="/about" exact component={AboutusScreen}/>
          <Route path="/faq" exact component={FAQScreen}/>
          <Route path="/projects" exact component={ProjectsScreen}/>
        </Switch>
      </BrowserRouter>
    </>
  );
};

export default App;

import Footerscreen from "./Footer"
import HeaderScreen from "./Header"


const ServicesDetailsScreen = () => {
  return (
    <>
    <section>
    <HeaderScreen/>
      <div>
      <div>
 
        {/* Header Area End */}
        {/* Page Banner Area Start */}
        <div className="page__banner" data-background="assets/img/page-banner.png">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="page__banner-content">
                  <h1>Services Details</h1>
                  <div className="page__banner-menu">
                    <ul>
                      <li><a href="/"><i className="flaticon-home-icon-silhouette" /> Home - </a>
                      </li>
                      <li> Services Details
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Page Banner Area End */}
        {/* Services Details Area Start */}
        <div className="services__details section-padding">
          <div className="container">
            <div className="row">
              <div className="col-xl-4 col-lg-4 lg-mb-30">
                <div className="services__details-left-sidebar">
                  <div className="services__details-widget-category mb-30">
                    <h3 className="mb-20">Category</h3>
                    <ul>
                      <li><a href="services-details">Cyber Security <i className="fas fa-chevron-right" /></a></li>
                      <li><a href="services-details">Cloud Solutions <i className="fas fa-chevron-right" /></a></li>
                      <li><a href="services-details">Data Recovery <i className="fas fa-chevron-right" /></a></li>
                      <li><a href="services-details">Business Support <i className="fas fa-chevron-right" /></a></li>
                      <li><a href="services-details">Network Cabling <i className="fas fa-chevron-right" /></a></li>
                    </ul>
                  </div>
                  <div className="services__details-widget-hours" data-background="assets/img/blog-2.jpg">
                    <h3 className="mb-20">Operating Hours</h3>
                    <ul>
                      <li>Sunday - Tuesday<p>7:00 - 4:00</p></li>
                      <li>Thursday<p>11:00 - 8:00</p></li>
                      <li>Friday <p>9:00 - 2:00</p></li>
                      <li>Saturday<p>Closed</p></li>
                    </ul>
                  </div>
                </div>                    
              </div>
              <div className="col-xl-8 col-lg-8">                    
                <div className="services__details-right-sidebar">
                  <div className="services__details-image mb-35">
                    <img src="assets/img/serrvices.jpg" alt="" />
                  </div>                        
                  <div className="services__details-content">
                    <h4 className="mb-25"><a href="services-details">Cyber Security</a></h4>
                    <p>Pellentesque et eros massa. Nulla porttitor dui vitae turpis tristique, sed vestibulum sapien molestie. Nulla fermentum maximus mi eget interdum. Pellentesque eget pharetra ipsum. Vestibulum at tincidunt velit. Mauris quis elit sit amet felis mollis volutpat non vitae dolor. Nullam eros sapien, malesuada semper lacinia sit amet, venenatis ut lectus. In ut dui maximus nunc molestie consequat a dignissim ipsum. Duis ligula tortor,</p>
                    <p>Aenean at tortor non eros molestie dictum sed non est. Quisque consequat nisl a leo aliquam consequat. Suspendisse viverra ultrices tellus at consequat. Nunc at lorem metus. Quisque non nunc condimentum, ultrices neque quis, rutrum sem. Curabitur tincidunt nulla eu velit efficitur,</p>                            
                    <div className="services__details-counter mb-25">
                      <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6 lg-mb-30">
                          <div className="counter__area-item">
                            <ul className="d-inline-flex align-items-center t-left">
                              <li><i className="flaticon-project-plan" />
                              </li>
                              <li>
                                <h2><span className="counter">225</span>K</h2>
                                <p>Complated Project</p>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 md-mb-30">
                          <div className="counter__area-item">
                            <ul className="d-inline-flex align-items-center t-left">
                              <li><i className="flaticon-customer-satisfaction" />
                              </li>
                              <li>
                                <h2><span className="counter">109</span>+</h2>
                                <p>National Award</p>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <p>Pellentesque et eros massa. Nulla porttitor dui vitae turpis tristique, sed vestibulum sapien molestie. Nulla fermentum maximus mi eget interdum. Pellentesque eget pharetra ipsum. Vestibulum at tincidunt velit. Mauris quis elit sit amet felis mollis volutpat non vitae dolor. Nullam eros sapien, malesuada semper lacinia sit amet, venenatis ut lectus. In ut dui maximus nunc molestie consequat a dignissim ipsum. Duis ligula tortor</p>
                    <ul className="mb-30">
                      <li><i className="flaticon-check" /> Rapid economic development</li>
                      <li><i className="flaticon-check" /> Telephone and radio equipment.</li>
                      <li><i className="flaticon-check" /> Video conferencing equipment.</li>
                    </ul>
                    <div className="services__details-services">
                      <h4 className="mb-30 pt-30">Releted Services</h4>
                      <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6 md-mb-30">
                          <div className="services__area-item details">
                            <div className="services__area-item-icon">
                              <img src="assets/img/icon/services-1.png" alt="" />
                            </div>
                            <h3><a href="services-details">Cyber Security</a></h3>
                            <p>Phasellus aliquam fermentum tincidunt. Duis accumsan,</p> <a href="services-details">Read More</a>
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6">
                          <div className="services__area-item details">
                            <div className="services__area-item-icon">
                              <img src="assets/img/icon/services-2.png" alt="" />
                            </div>
                            <h3><a href="services-details">Cloud Solutions</a></h3>
                            <p>Phasellus aliquam fermentum tincidunt. Duis accumsan,</p> <a href="services-details">Read More</a>
                          </div>
                        </div>                                    
                      </div>
                    </div>
                  </div>                        
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
        <Footerscreen/>
    </section>
    </>
  )
}

export default ServicesDetailsScreen 